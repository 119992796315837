import React from "react";
import { Link } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";
import { links } from "../content/Links";

const Footer = () => {
  return (
    <>
      <div className="fit-container footer">
        <div className="logo" />
        <div className="footer-links">
          {links.map((link) => (
            <Link key={link._id} to={link.name} smooth={true} duration={500}>
              <p className="white-c pointer">{link.entitle}</p>
            </Link>
          ))}
        </div>

        {/* <div className="footer-socials">
          <RouterLink>
            <div className="linkedin" />
          </RouterLink>
          <RouterLink>
            <div className="instagram" />
          </RouterLink>
          <RouterLink>
            <div className="facebook" />
          </RouterLink>
        </div> */}
      </div>
      <div className="footer-mobile fit-container">
        <div className="logo" />
        <div className="fx-scattered footer-mobile-links">
          <div className="fx-col gap-16">
            {links.map(
              (link, index) =>
                index <= 2 && (
                  <Link
                    key={link._id}
                    to={link.name}
                    smooth={true}
                    duration={500}
                  >
                    <p className="white-c pointer">{link.entitle}</p>
                  </Link>
                )
            )}
          </div>

          <div className="fx-col gap-16">
            {links.map(
              (link, index) =>
                index > 2 && (
                  <Link
                    key={link._id}
                    to={link.name}
                    smooth={true}
                    duration={500}
                  >
                    <p className="white-c pointer">{link.entitle}</p>
                  </Link>
                )
            )}
          </div>
        </div>

        <div className="fx-centered fit-container">
          <p className="p-medium p-centered gray-c">Tous droits résérvés, Romy 2024</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
