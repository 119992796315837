import React from "react";
import { Link } from "react-scroll";

const ImgCard = ({
  type,
  title,
  text,
  imgStyle,
  cardStyle,
  img,
  cardContainer,
  section
}) => {
  return (
    <div className={cardStyle}>
      <div className="card-text">
        <h3 className="p-700" style={{ fontSize: "var(--32)" }}>
          {title}
        </h3>
        {/* <p>{text}</p> */}
      </div>

      {type === "large" ? (
        // <img alt="image-large" src={img} className={imgStyle} />
        <div className={`${cardContainer}`}>
          <div className={img} />
        </div>
      ) : (
        <div className="small-card-img-container">
          <div className={`img-box ${img}`}></div>
        </div>
      )}

      <Link offset={-70} smooth to={section} duration={500}>
        <div className="fx-centered pointer plus-container plus-container-right">
          <div className="arrow_down" />
        </div>
      </Link>
    </div>
  );
};

export default ImgCard;
