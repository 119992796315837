import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Soulution from "../components/Soulution";
import Outils from "../components/Outils";
import Services from "../components/Services";
import Connection from "../components/Connection";
import MedIA from "../components/MedIA";
import Questions from "../components/Questions";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import ToastMessage from "../components/toastMessages/ToastMessage";
import MobileApp from "../components/MobileApp";

const LandingPage = () => {
  const [toastMessage, setToastMessage] = useState(false);
  return (
    <div className="fit-container main-container">
      <ToastMessage
        toastMessage={toastMessage}
        setToastMessage={setToastMessage}
      />
      <div className="fixed-container">
        <Navbar />
        <Hero />
        <div name="caractéristiques">
          <Soulution />
          <Outils />
          <Services />
          <Connection />
        </div>
        <MobileApp />
        <MedIA />
        <Questions />
        <Contact setToastMessage={setToastMessage} />
        <Footer />
      </div>
    </div>
  );
};

export default LandingPage;
