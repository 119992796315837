const solutionCards = [
  {
    _id: 0,
    title: "Maitrisez vos risques",
    text: "Au cœur de la solution, le module Cartographie et Évaluation vous donne une vue globale de vos risques",
    image: "risks_management",
    cardContainer: "large-card-container",
    section: "risks",
  },
  {
    _id: 1,
    title: "Gagnez en productivité",
    text: "Avec son module de Task Management inspiré des méthodes modernes de gestion de projet ; transformez votre vision stratégique en actions concrètes.",
    image: "productivity",
    cardContainer: "tasks-large-card-container",
    section: "productivity",
  },
  {
    _id: 2,
    title: "Pilotez votre performance",
    text: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Rerum, quia at ipsam quo iusto earum accusantium porro harum ea  ",
    image: "performance",
    section: "performance",
  },
  {
    _id: 3,
    title: "Connectez vos équipes",
    text: " Une image contenant Graphique, cercle, Caractère coloré, symbole Description générée automatiquement ",
    image: "connection_picture",
    section: "team-connect",
  },
  {
    _id: 4,
    title: "Renforcez votre vigilance",
    text: "L'IA surveille constamment les médias pour repérer les problèmes potentiels chez les fournisseurs.",
    image: "refrence",
    section: "vigilance",
  },
];

const modelsData = [
  {
    _id: 0,
    entitle: "Cartographie des risques",
    text: "Au cœur de la solution Romy, le module  Cartographie et Évaluation  vous donne une vue globale de vos risques ; couvrant votre organisation, ses entités affiliées et ses partenaires. ",
    detailedText: [
      "Au cœur de la solution, le module « Cartographie et Évaluation » vous donne une vue globale de vos risques ; couvrant votre organisation, ses entités affiliées et ses partenaires.",
      "Il vous offre une flexibilité totale pour personnaliser vos échelles de mesure et réaliser des évaluations sur tous vos domaines d’activité.",
      "Élaborez vos modèles de cartographie, déployez vos campagnes d'évaluation, et suivez l'évolution du niveau de vos risques grâce à des tableaux de bord interactifs.",
    ],
    images: ["carthographie medium-h-card"],
    plusPosition: "plus-container-right",
    cardType: "medium-card-h",
  },
  {
    _id: 1,
    entitle: "Registre cadeaux et invitations",
    text: "Pour assurer une transparence totale au sein de votre organisation et éviter tout risque de non-conformité.",
    detailedText: [
      "Pour assurer une transparence totale au sein de votre organisation et éviter tout risque de non-conformité.",
      "Gérez les déclarations de cadeaux et invitations de vos employés avec notre Registre dédié.",
      "Ce module centralise toutes les déclarations via un portail intuitif et vous assure une gestion simple et proactive.",
    ],
    images: ["gifts", "gifts_confirm absolute bottom"],
    plusPosition: "plus-container-right",
    cardType: "high-card-h",
  },

  {
    _id: 2,
    entitle: "Gestion des questionnaires",
    text: "Le module  « Gestion des Questionnaires » facilite la collecte d’information. Que ce soit en interne, en identifiant les risques liés à vos métiers, ou en externe pour l'évaluation des tiers. ",
    detailedText: [
      "Le module  « Gestion des Questionnaires » facilite la collecte d’information. Que ce soit en interne, en identifiant les risques liés à vos métiers, ou en externe pour l'évaluation des tiers.",
      "Créez et gérez autant de questionnaires que nécessaire !",
      "Le traitement des réponses est facilité par une interface d'analyse intuitive et fiabilisé grâce à une approche Data Quality By Design qui élimine les redondances.",
      "La plateforme formalise les échanges avec des notifications et des relances par email automatisées, garantissant ainsi une collecte de données fluide et structurée.",
    ],
    images: ["survey absolute bottom"],
    plusPosition: "plus-container-left",
    cardType: "high-card-h min-h-620px",
  },

  {
    _id: 3,
    entitle: "Évaluation des tiers",
    text: "Accélérez l'évaluation de vos tiers avec notre module dédié.",
    detailedText: [
      "Accélérez l'évaluation de vos tiers avec notre module dédié.",
      "Notre approche innovante vous offre la liberté de concevoir des évaluations sur mesure, en sélectionnant les questions, les pondérations et les modes d’évaluation selon vos besoins.",
      "Enrichi par des algorithmes l’Intelligence Artificielle pour le Media Screening, ce module vous permet de détecter rapidement les menaces potentielles liées à vos fournisseurs. En quelques secondes, explorez une vaste base de données d’articles de journaux couvrant une quarantaine de pays et plus de 20 000 sources.",
    ],
    images: ["thirdparty_management thirdparty_management_position"],
    plusPosition: "plus-container-right",
    cardType: "medium-card-h",
  },

  {
    _id: 4,
    entitle: "Task Management",
    text: "Pour orchestrer la gestion des risques, Romy vous offre une vue d'ensemble de tous vos plans d’action ainsi que de leurs déclinaison opérationnelle en tâches.",
    detailedText: [
      "Pour orchestrer la gestion des risques, Romy vous offre une vue d'ensemble de tous vos plans d’action. Suivez leur progression, identifiez les retards, et prenez des décisions rapides et éclairées.",
      "À la différence d'autres solutions, Romy ne se limite pas à la vision « feuille de route. »",
      "Avec son module de « Task Management » inspiré des méthodes modernes de gestion de projet ; transformez votre vision stratégique en actions concrètes.",
      "Suivez chaque tâche avec précision grâce à des tickets détaillés, des priorités claires, une traçabilité exemplaire et des échéances définies, pour une coopération et une efficacité optimale.",
    ],
    images: ["tasks_management absolute bottom"],
    plusPosition: "plus-container-right",
    cardType: "medium-card-h custom-model-card",
  },

  {
    _id: 5,
    entitle: "Comitologie et processus décisionnel",
    text: "La « Gestion de la Comitologie » centralise l’organisation et la planification de vos comités et réunions stratégiques.",
    detailedText: [
      "La « Gestion de la Comitologie » centralise l’organisation et la planification de vos comités et réunions stratégiques.",
      "Ce module vous permet de planifier et synchroniser les rencontres, de partager et valider les supports de présentation à travers différents niveaux hiérarchiques et de coordonner les échanges.",
      "Grâce à la gestion des comptes rendus et au suivi des décisions, vous pilotez l'activité de conformité avec transparence et efficacité.",
    ],
    images: ["comitologie"],
    plusPosition: "plus-container-right",
    cardType: "medium-card-h custom-model-card min-h-560px",
  },

  {
    _id: 6,
    entitle: "Report Builder",
    text: "Notre Report Builder simplifie la création de rapports et présentations, vous faisant gagner un temps précieux.",
    detailedText: [
      "Notre Report Builder simplifie la création de rapports et présentations, vous faisant gagner un temps précieux.",
      "S’articulant particulièrement bien avec le module « comitologie », il permet de mettre à jour vos supports dynamiquement.",
      "Ce processus, autrefois chronophage et source d'erreurs, devient fluide et rigoureux.",
      "Les indicateurs sont mis à jour quasi-instantanément et les présentations, créées à partir de templates, sont exportables en format Power Point pour une diffusion simplifiée.",
    ],
    images: ["report_builder"],
    plusPosition: "plus-container-right",
    cardType: "h-full service-card-mobile min-h-560px min-h-510px",
  },

  {
    _id: 7,
    entitle: "Dashboard",
    text: "Les dashboards interactifs offrent une vue à la fois globale et détaillée de votre organisation, permettant de suivre en un coup d'œil l'évolution des risques, l'avancement des plans d'action, et la progression des tâches etc. ",
    detailedText: [
      "Les dashboards interactifs offrent une vue à la fois globale et détaillée de votre organisation, permettant de suivre en un coup d'œil l'évolution des risques, l'avancement des plans d'action, et la progression des tâches etc.",
      "Chaque module dispose de ses propres indicateurs clés, qu'il s'agisse du taux de réponse aux questionnaires, des audits en cours, ou de la performance des équipes.",
      "Grâce à des mises à jour en temps réel, ces tableaux de bord vous fournissent une vision stratégique tout en vous permettant de plonger dans les détails. Cette approche centralisée et fluide vous donne les outils pour piloter vos opérations avec une précision et une réactivité optimales.",
    ],
    images: ["dashboard"],
    plusPosition: "plus-container-left",
    cardType: "h-full service-card-mobile hidden",
  },
];

const connection = [
  {
    _id: 0,
    entitle: "Réseau social professionnel",
    text: "Fédérez vos équipes grâce à notre Réseau Social Professionnel, un espace de partage, aussi bien en interne qu’en externe. Qu'il s'agisse de collaborer sur un projet, diffuser un rapport, ou organiser un événement, cet outil renforce la cohésion et l’efficacité de vos équipes tout en élargissant votre réseau professionnel.",
    images: ["news_feed absolute bottom"],
    cardType: "medium-card-h news-feed-card connection-card hidden",
  },

  {
    _id: 1,
    entitle: "Une messagerie intégrée",
    text: "La Messagerie intégrée, sécurisée et cryptée, facilite les communications individuelles ou en groupe. Elle vous permet de rester connecté avec vos équipes tout en garantissant la confidentialité des échanges.",
    images: ["direct_messages"],
    cardType:
      "medium-card-h direct-messages-card connection-card flex fx-col fx-centered fx-start-h hidden",
  },
];

const medIAServices = [
  {
    _id: 0,
    entitle: "Surveillance des Menaces en Ligne",
    text: "Identifiez les menaces potentielles pour une entreprise grâce à une surveillance continue du web, ciblant la fraude, les violations de sécurité et autres risques.",
    icon: "search",
    position: "card-left",
  },

  {
    _id: 1,
    entitle: "Analyse des Résultats",
    text: "Analysez les données collectées pour évaluer l'impact des menaces, les classer par priorité et définir des stratégies de réponse.",
    icon: "chart",
    position: "card-right",
  },

  {
    _id: 2,
    entitle: "Génération de Rapports",
    text: "Enregistrez les résultats de l'analyse sous forme de rapports détaillés, offrant une vue d'ensemble des menaces et des mesures recommandées.",
    icon: "todos",
    position: "card-left",
  },
];

const questions = [
  {
    _id: 0,
    entitle: "A qui s’adresse votre solution ?",
    text: "Notre solution SaaS s'adresse à toutes les personnes impliquées dans le domaine de la Gouvernance, des Risques et de la Conformité (GRC). Que vous soyez Responsable des Risques, Compliance Officer, Responsable de la Conformité, Auditeur Interne, Responsable Sécurité ou encore Directeur des Affaires Juridiques, notre outil est conçu pour faciliter la gestion des risques tout en optimisant la gestion du travail et la communication au sein de votre organisation.",
  },
  {
    _id: 1,
    entitle:
      "Votre solution est-elle adaptée à différents secteurs d’activité ?",
    text: "Conçue pour être personnalisée selon vos besoins, notamment grâce à la flexibilité des échelles de mesure ; notre plateforme peut évoluer avec votre organisation, quelle que soit la taille de votre entreprise ou la complexité de votre secteur. Que vous soyez dans la finance, la santé, l'industrie, ou tout autre domaine, notre solution s'ajuste pour répondre précisément à vos défis et vous offrir une gestion optimale de vos risques.",
  },
  {
    _id: 2,
    entitle: "Où sont hébergées les données de ma société ?",
    text: "Notre solution SaaS est hébergée sur AWS (Amazon Web Services), un leader mondial reconnu pour la sécurité et la fiabilité de ses infrastructures cloud. Afin de garantir la conformité avec les réglementations locales et assurer une protection optimale des données, toutes vos informations sont stockées dans des centres de données situés en France. Nous nous engageons à respecter les normes les plus strictes en matière de sécurité et de confidentialité, tout en vous offrant une performance de service à la hauteur de vos attentes.",
  },
  {
    _id: 3,
    entitle: "Proposez-vous une période d’essai ?",
    text: "Nous offrons une période d'essai gratuite de 1 mois pour vous permettre de découvrir toutes les fonctionnalités de notre solution SaaS de Gouvernance, Risques et Conformité (GRC). Cette période vous donne un accès complet à l'outil, afin que vous puissiez l'explorer en profondeur et évaluer comment il peut répondre aux besoins spécifiques de votre organisation. Aucun engagement n'est requis, et vous pouvez profiter pleinement de toutes les capacités de la plateforme durant cette période.",
  },
  {
    _id: 4,
    entitle: "Puis-je accéder à la solution SaaS depuis un appareil mobile ?",
    text: "Notre solution SaaS est accessible à tout moment, que ce soit via le web ou notre application mobile. Vous pouvez ainsi gérer la Gouvernance, les Risques et la Conformité de votre organisation de manière flexible, depuis votre ordinateur, tablette ou smartphone. Cette accessibilité multiplateforme vous permet de suivre vos projets en temps réel, où que vous soyez, pour une gestion optimale, même en déplacement.",
  },
  {
    _id: 5,
    entitle: "Comment votre outil évolue-t-il au fil du temps ?",
    text: "Chez Romy, nos clients sont au cœur de notre processus de développement. Chaque client bénéficie d'une représentation dans notre Product Advisory Board qui se réunit tous les trois mois, où ils peuvent partager leurs retours d'expérience et leurs besoins spécifiques. Cela nous permet d'adapter notre roadmap de développement en fonction des attentes réelles de nos utilisateurs. Cette approche collaborative nous permet de construire ensemble une solution qui évolue en permanence pourrépondre aux défis de gouvernance, risques et conformité de manière optimale.",
  },
];

export { solutionCards, modelsData, medIAServices, connection, questions };
