import React from "react";
import ImgCard from "./ui/ImgCard";
import { solutionCards } from "../content/Content";

const Soulution = () => {
  return (
    <div className="solution-container fit-container fx-centered box-pad-v">
      <div className="fit-container solution-bg main-bg bg-img" />
      <div className="wrapper">
        <div className="solution-content">
          <div className="fx-centered fx-col gap-16 w-80">
            <h1
              className="white-c p-centered line-h-85"
              style={{ letterSpacing: "-1px" }}
            >
              Une solution complète et unifiée pour vos besoins en GRC
            </h1>
            <p className="gray-c p-centered" style={{ fontSize: "var(--20)" }}>
              Romy aligne stratégie et opérations, libère vos équipes des tâches
              chronophages, et améliore leur efficacité au quotidien.
            </p>
          </div>

          <div
            className="fit-container sections-data-mobile fx-col fx-centered"
            style={{ rowGap: "var(--64)" }}
          >
            <div className="fx-centered main-sections-container box-pad-h fit-container gap-32">
              {solutionCards.map(
                (card, index) =>
                  index < 2 && (
                    <ImgCard
                      key={card._id}
                      text={card.text}
                      title={card.title}
                      img={card.image}
                      cardStyle={"card-content"}
                      imgStyle={"large-card-img"}
                      type={"large"}
                      cardContainer={card.cardContainer}
                      section={card.section}
                    />
                  )
              )}
            </div>

            <div className="fx-centered fx-wrap other-sections-container box-pad-h fit-container gap-24">
              {solutionCards.map(
                (card, index) =>
                  index >= 2 && (
                    <ImgCard
                      key={card._id}
                      text={card.text}
                      title={card.title}
                      img={card.image}
                      cardStyle={"card-content small-card-content"}
                      imgStyle={"small-card-img"}
                      type={"small"}
                      section={card.section}
                    />
                  )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Soulution;
