import React, { useState } from "react";

const Question = ({ question }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      className="question-data pointer"
      style={{
        maxHeight: !isOpen ? "220px" : "550px",
        gap : isOpen && "var(--20)"
      }}
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className="fx-scattered fit-container">
        <h3 className="white-c">{question.entitle}</h3>
        <div
          className={`gray_plus_sign ${isOpen && "close-button"} pointer`}
        />
      </div>

      <p
        className={`gray-c ${isOpen ? "fade-down" : "hide"}`}
        style={{
          transform: `${!isOpen ? "translateY(-300px)" : "translateY(0px)"}`,
          maxHeight : isOpen ? "550px" : "0px",
          transition : "0.3s ease-out"
        }}
      >
        {question.text}
      </p>
    </div>
  );
};

export default Question;
