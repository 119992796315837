import React, { useState } from "react";
import { ButtonRsrv } from "./ui/ButtonRsrv";
import { axiosInstance } from "../outils/HTTP_CLIENT";

const Contact = ({ setToastMessage }) => {
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState("");
  const [phone, setPhone] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onSendMessage = async (e) => {
    e.preventDefault();
    if (isLoading) return;
    try {
      setIsLoading(true);
      if (!name || !email || !message) {
        if (!name) {
          setNameError("Le nom est obligatoire");
        }
        if (!email) {
          setEmailError("L'e-mail est obligatoire");
        }
        if (!message) {
          setMessageError("Le message est obligatoire");
        }
        setIsLoading(false);
      }
      if (message && name && email) {
        let formData = new FormData();
        formData.append(`email`, email);
        formData.append(`name`, name);
        formData.append(`message`, message);
        formData.append(`number`, phone);
        await axiosInstance.post(
          `https://app.romyapp.io/api/v1/clients-contact`,
          formData
        );
        setIsLoading(false);
        setMessage("");
        setName("");
        setEmail("");
        setPhone("");
        setToastMessage({
          type: 1,
          title: "Message envoyé",
          message:
            "Votre message a bien été envoyé! Une personne de l'équipe Romy prendra contact avec vous",
        });
      }
    } catch (error) {
      setIsLoading(false);
      setToastMessage({
        type: 2,
        title: "Error envoyer message",
        message: "Erreur. Votre message n’a pas pu être envoyé",
      });
      console.log(error);
    }
  };
  return (
    <div name="contact" className="fit-container contact">
      <div className="contact-section fit-container">
        <div className="flex fx-col half-container gap-32 fx-start-h contact-text">
          <div className="contact-text-data fx-col gap-24">
            <h2 className="white-c">Contactez-nous</h2>
            <p className="gray-c">
              N'hésitez pas à nous contacter pour toute question ou demande
              d'assistance. Notre équipe est là pour vous aider et répondre à
              vos questions. Nous sommes impatients de vous entendre !
            </p>
          </div>

          <div className="flex contact-buttons gap-24">
            <ButtonRsrv
              text={"Réserver une demo"}
              styles={"btn-rsrv custom-rsrv-bg"}
            />
          </div>
        </div>

        <form name="form" className="fx-col gap-20 half-container" onSubmit={onSendMessage}>
          <div className="fit-container fx-col">
            <input
              className="ifs-full if"
              type="text"
              placeholder="Prénom et nom"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setNameError("");
              }}
            />
            {nameError && <p className="red-c box-pad-h">{nameError}</p>}
          </div>
          <div className="fit-container fx-col">
            <input
              className="ifs-full if"
              type="email"
              placeholder="Email professionnel"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailError("");
              }}
            />
            {emailError && <p className="red-c box-pad-h">{emailError}</p>}
          </div>
          <div className="fit-container">
            <input
              className="ifs-full if"
              type="text"
              placeholder="Téléphone (optionnel)"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div className="fit-container fx-col">
            <textarea
              className="ifs-full txt-area if"
              placeholder="Message"
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
                setMessageError("");
              }}
            />
            {messageError && <p className="red-c box-pad-h">{messageError}</p>}
          </div>
          <div className="fit-container fx-centered">
            <button className="btn btn-send" disabled={isLoading}>
              <div className={`send ${isLoading && "sliding"}`} />
              {/* {isLoading ? <LoadingDots /> : "Envoyer"} */}
            </button>
          </div>
          {successMessage && (
            <p className="white-c p-medium">{successMessage}</p>
          )}
        </form>
      </div>
    </div>
  );
};

export default Contact;
