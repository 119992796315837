import React from "react";
import { questions } from "../content/Content";
import Question from "./ui/Question";

const Questions = () => {
  return (
    <div name="faq" className="fit-container relative fx-centered box-pad-v-l">
      <div className="fit-container questions-bg main-bg bg-img" />
      <div className="fit-container fx-centered fx-col gap-24">
        <div className="questions-text fx-centered fx-col gap-24">
          <h2 className="white-c">Questions fréquentes</h2>

          <h3 className="blue-c p-700">Tout savoir sur notre solution GRC</h3>

          <p
            className="gray-c p-centered w-80"
            style={{
              fontSize: "var(--20)",
              lineHeight: "26.5px",
            }}
          >
            Découvrez les réponses aux questions les plus courantes pour
            comprendre comment notre solution peut vous aider à mieux gérer la
            gouvernance, les risques et la conformité.
          </p>
        </div>

        <div className="questions-container fx-col gap-16 w-80">
          {questions.map((question) => (
            <Question key={question._id} question={question} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Questions;
