import React, { useEffect } from "react";
import Green from "./Green";
// import Orange from "./Orange";
import Red from "./Red";
// import Blue from "./Blue";

export default function ToastMessage({toastMessage, setToastMessage}) {
  useEffect(() => {
    let timeout = setTimeout(() => {
      setToastMessage(false);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [toastMessage]);
  if (!toastMessage) return null;
  return (
    <div className="toast-message toast-message-animation">
      {toastMessage.type === 1 && <Green title={toastMessage.title} message={toastMessage.message} />}
      {toastMessage.type === 2 && <Red title={toastMessage.title} message={toastMessage.message} />}
      {/* {toastMessage.type === 3 && <Orange title={toastMessage.title} message={toastMessage.message} />}
      {toastMessage.type === 4 && <Blue title={toastMessage.title} message={toastMessage.message} />} */}
    </div>
  );
}
