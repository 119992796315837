const links = [
  {
    _id: 0,
    entitle: "Demo",
    name: "demo",
  },
  {
    _id: 1,
    entitle: "Fonctionnalités",
    name: "caractéristiques",
  },
  {
    _id: 2,
    entitle: "App mobile",
    name: "app-mobile",
  },
  {
    _id: 3,
    entitle: "Module IA",
    name: "module",
  },
  {
    _id: 4,
    entitle: "FAQ",
    name: "faq",
  },
  {
    _id: 5,
    entitle: "Contact",
    name: "contact",
  },
];

export { links };
