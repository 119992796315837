import React from "react";
import { Link } from "react-router-dom";

export const ButtonRsrv = ({ styles, text }) => {
  return (
    <button className={`btn ${styles} fx-centered`}>
      <div
        className="circle"
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="arrow" />
      </div>
      <p className="btn-text">{text}</p>
      <div style={{ width: "20px", height: "20px" }} />
      <Link
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          cursor: "pointer",
          zIndex: "10",
        }}
        to={"https://outlook.office365.com/book/RomyRservationsdedemo@konceptt.com/"}
        target="_blank"
      >
        
      </Link>
    </button>
  );
};
