import React from 'react'

export default function Red({ title, message }) {
    return (
        <div className='red-toast fx-scattered gap-14'>
            <div className='not_allowed'></div>
            <div style={{ width: '90%' }}>
                <h5 className='box-pad-v-s white-c'>{title}</h5>
                <p className='p-medium white-c'>{message}</p>
            </div>
        </div>
    )
}
