import React from "react";
import { medIAServices } from "../content/Content";
import { MedIAServiceCard } from "./ui/MedIAServiceCard";

const MedIA = () => {
  return (
    <div name="module" className="fit-container box-pad-v-l">
      <div className="fx-col gap-24 fx-centered fit-container fx-streach">
        <div name="vigilance" className="med-ia-text">
          <h2
            className="white-c p-centered w-80"
            style={{ lineHeight: "62px", letterSpacing: "0" }}
          >
            Renforcez votre <span className="blue-c">vigilance</span> grace à notre outil d'Analyse <span className="blue-c">Med.IA</span>
          </h2>
          <p
            className="gray-c p-centered w-80"
            style={{
              fontSize: "var(--20)",
              lineHeight: "26.5px",
            }}
          >
            L'IA réalise une veille en continu sur des milliers de sources
            médiatiques afin d’identifier des signaux faibles concernant vos
            fournisseurs, les sanctions d'entreprises du même secteur, ainsi que
            d'autres événements pertinents pour vos activités.
          </p>
        </div>

        <div className="fit-container fx-centered">
          <div className="med-ia-services med_ia">
            {medIAServices.map((service) => (
              <div key={service._id} className={service.position}>
                <MedIAServiceCard service={service} />
              </div>
            ))}
            {/* <div className="service-card">
              <div className="search" />
              <h3 className="white-c p-700">
                Surveillance des Menaces en Ligne
              </h3>
              <p className="gray-c">
                Identifiez les menaces potentielles pour une entreprise grâce à
                une surveillance continue du web, ciblant la fraude, les
                violations de sécurité et autres risques.
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MedIA;
