import React from "react";

const Outils = () => {
  return (
    <div
      className="fit-container outils-container fx-centered fx-col relative box-pad-v-l gap-24"
    >
      <h1
        className="white-c p-centered w-80 line-h-85"
        style={{ letterSpacing: "-1px" }}
      >
        Révélez votre plein potentiel grâce aux bons outils
      </h1>

      <div
        className="outils relative outils-overly"
        style={{
          backgroundPosition: "right",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      />
    </div>
  );
};

export default Outils;
