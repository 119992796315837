import React, { useState } from "react";

export const ModelCard = ({
  _id,
  plusPosition,
  entitle,
  text,
  cardType,
  images,
  plusIcon = true,
  detailedText,
}) => {
  const [showDetailedText, setShowDetailedText] = useState("");

  return (
    <div
      className={`page-model-container ${cardType} ${
        _id === 4 && showDetailedText && "min-h-660px"
      } ${_id === 3 && showDetailedText && "min-h-580px"} gap-24`}
    >
      <div className="page-model-txt fx-col gap-16">
        <h3
          className="p-700 white-c"
          style={{
            zIndex: "7",
          }}
        >
          {entitle}
        </h3>
        <p className="gray-c">{text}</p>
      </div>

      {images.map((img) => (
        <div key={img} className={img} />
      ))}

      {/* <div className="carthographie medium-h-card" /> */}

      {plusIcon && (
        <div
          className={`fx-centered pointer plus-container ${plusPosition}`}
          style={{ transform : showDetailedText && 'rotate(45deg)' }}
          onClick={() => {
            if (showDetailedText === "" || !showDetailedText) {
              setShowDetailedText(true);
            } else {
              setShowDetailedText(false);
            }
          }}
        >
          <div className="plus_sign" />
        </div>
      )}

      <div
        style={{
          zIndex: showDetailedText && "5",
          backgroundColor: showDetailedText && "var(--main-blue)",
        }}
        className={`detailed-text-info ${
          showDetailedText === true
            ? "fade-down"
            : showDetailedText === false
            ? "fade-up"
            : "visibility-hidden"
        }`}
      >
        <div
          className={`${
            showDetailedText === true
              ? "slide-down"
              : showDetailedText === false
              ? "slide-up"
              : ""
          } fx-col gap-16`}
        >
          {detailedText?.map((text) => (
            <p key={text} className={`white-c`}>
              {text}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};
