import React from "react";

export const MedIAServiceCard = ({ service }) => {
  return (
    <div className="service-card">
      <div className={service.icon} />
      <h3 className="white-c p-700">{service.entitle}</h3>
      <p className="gray-c">{service.text}</p>
    </div>
  );
};
