import React from "react";
import { ModelCard } from "./ui/ModelCard";
import { connection } from "../content/Content";

const Connection = () => {
  return (
    <div className="fit-container box-pad-v-l">
      <div className="fit-container fx-centered fx-stretch fx-col gap-32">
        <div name="team-connect" className="fx-centered connection-text-mobile fx-col gap-32">
          <h2 className="white-c p-centered" style={{ lineHeight: "62px" }}>
            <span className="blue-c">Connectez</span> vos équipes
          </h2>
          <p
            className="gray-c w-70 p-centered"
            style={{
              fontSize: "var(--20)",
              lineHeight: "26.5px",
            }}
          >
            Simplifiez la communication et la collaboration avec des outils
            intégrés tels que la messagerie instantanée et le fil d'actualité,
            et assurez une accessibilité totale grâce à notre application
            mobile, où que vous soyez.
          </p>
        </div>

        <div className="team" />

        <div className="fx-centered fx-stretch connection-modules-mobile gap-32 gap box-pad-h-l">
          <ModelCard
            key={connection[0]._id}
            plusIcon={false}
            entitle={connection[0].entitle}
            text={connection[0].text}
            cardType={connection[0].cardType}
            images={connection[0].images}
          />

          <ModelCard
            key={connection[1]._id}
            plusIcon={false}
            entitle={connection[1].entitle}
            text={connection[1].text}
            cardType={connection[1].cardType}
            images={connection[1].images}
          />
        </div>

      </div>
    </div>
  );
};

export default Connection;
