import React from "react";

export default function Green({ title, message }) {
  return (
    <div className="green-toast fx-scattered gap-14">
      <div className="like"></div>
      <div style={{ width: "90%" }}>
        <h5 className="box-pad-v-s white-c">{title}</h5>
        <p className="p-medium white-c">{message}</p>
      </div>
    </div>
  );
}
