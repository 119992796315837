import "./styles/essentials.css";
import "./styles/icons.css";
import "./styles/custom.css";
import "./styles/animations.css";
import "./styles/mobile.css";

import LandingPage from "./pages/LandingPage";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <LandingPage />
      </BrowserRouter>
    </div>
  );
}

export default App;
