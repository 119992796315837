import React, { useState } from "react";

const MobileApp = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div name="app-mobile" className="fit-container box-pad-v-l">
      <div className="fit-container app-sections">
        <div className="text">
          <h2 style={{ lineHeight: "62px", letterSpacing: "0" }}>
            Tout à portée <span className="blue-c">de main</span> où que{" "}
            <span className="blue-c">vous soyez</span>
          </h2>
          <p
            className="gray-c p-centered w-80"
            style={{
              fontSize: "var(--20)",
              lineHeight: "26.5px",
              zIndex: "2",
              backgroundColor: "var(--dominant)",
            }}
          >
            L'application mobile vous met à portée de main toutes les
            informations essentielles en temps réel. Les notifications
            instantanées assurent une réactivité immédiate aux événements
            critiques.
          </p>

          <p
            className={`gray-c p-centered w-80 ${
              isOpen ? "slide-down" : "slide-up"
            }`}
            style={{
              fontSize: "var(--20)",
              lineHeight: "26.5px",
              maxHeight: isOpen ? "500px" : "0px",
              transition: "0.3s all",
            }}
          >
            La collaboration s’en trouve simplifiée : répondez aux commentaires,
            créez des tâches et interagissez avec vos collègues, même lorsque
            vous êtes en déplacement. Grâce au fil d'actualité et à la
            messagerie intégrée, l'application devient un véritable hub
            d'échanges et de collaboration.
          </p>

          <div
            className={`fx-centered pointer plus-container`}
            style={{
              backgroundColor: "#313236",
              position: "unset",
              transform: isOpen && "rotate(45deg)",
              transition: "0.6s all",
            }}
            onClick={() => setIsOpen(!isOpen)}
          >
            <div className="plus_sign" />
          </div>
        </div>

        <div
          className={`mobile_app`}
          // style={{
          //   transform: isOpen ? "translateY(100%)" : "translateY(0px)",
          //   transition: "0.6s all",
          // }}
        />
      </div>
    </div>
  );
};

export default MobileApp;
