import React from "react";
import { modelsData } from "../content/Content";
import { ModelCard } from "./ui/ModelCard";

const Services = () => {
  return (
    <div className="fit-container sections-container box-pad-v-l box-pad-h-l">
      <div className="fit-contanier fx-centered gap-48 fx-col">
        <div name="risks" className="fx-col gap-32">
          <div
            className="fit-container services-sections-text-mobile fx-scattered"
            style={{ paddingBottom: "var(--20)" }}
          >
            <div className="half-container title-content">
              <h2
                className="white-c half-container"
                style={{ lineHeight: "62px" }}
              >
                Maitrisez vos <span className="blue-c">risques</span>
              </h2>
            </div>
            <p
              className="gray-c"
              style={{
                fontSize: "var(--20)",
                lineHeight: "26.5px",
                transform: "translateY(var(--40))",
                width: "44%",
              }}
            >
              Anticipez et gérez les risques avec précision grâce à des outils
              puissants d'analyse, d'évaluation et de suivi en temps réel
            </p>
          </div>

          <div className="flex sections-card-mobile gap-32 fit-container">
            <div className="fx-col gap-32">
              {modelsData.map(
                (module, index) =>
                  index < 2 && (
                    <ModelCard
                      key={module._id}
                      plusPosition={module.plusPosition}
                      entitle={module.entitle}
                      text={module.text}
                      cardType={module.cardType}
                      images={module.images}
                      detailedText={module.detailedText}
                      _id={module._id}
                    />
                  )
              )}
            </div>

            <div className="fx-col gap-32">
              {modelsData.map(
                (module, index) =>
                  index >= 2 &&
                  index < 4 && (
                    <ModelCard
                      key={module._id}
                      plusPosition={module.plusPosition}
                      entitle={module.entitle}
                      text={module.text}
                      cardType={module.cardType}
                      images={module.images}
                      detailedText={module.detailedText}
                      _id={module._id}
                    />
                  )
              )}
            </div>
          </div>
        </div>

        <div name="productivity" className="fx-col gap-32">
          <div className="fit-container text-column fx-scattered">
            <div className="half-container">
              <h2 className="white-c w-80" style={{ lineHeight: "62px" }}>
                <span className="blue-c">Optimisez </span>
                votre organisation et <span className="blue-c">gagnez</span> en
                productivité
              </h2>
            </div>
            <p
              className="gray-c"
              style={{
                fontSize: "var(--20)",
                lineHeight: "26.5px",
                width: "44%",
              }}
            >
              Optimisez la gestion de vos projets et flux de travail avec une
              organisation simplifiée, des automatisations intelligentes, et une
              visibilité complète sur l'avancement de vos tâches.
            </p>
          </div>

          <div className="fit-container">
            <div className="fx-scattered tasks-services-mobile gap-32">
              {modelsData.map(
                (module, index) =>
                  index >= 4 &&
                  index < 6 && (
                    <ModelCard
                      key={module._id}
                      plusPosition={module.plusPosition}
                      entitle={module.entitle}
                      text={module.text}
                      cardType={module.cardType}
                      images={module.images}
                      detailedText={module.detailedText}
                      _id={module._id}
                    />
                  )
              )}
            </div>
          </div>
        </div>

        <div name="performance" className="flex fx-scattered reports-services-mobile fx-stretch gap-32">
          <div className="fx-col fx-streach half-container service-column-mobile gap-32">
            <div className="fx-col text-data gap-48">
              <h2 className="white-c" style={{ lineHeight: "62px" }}>
                Pilotez votre <span className="blue-c">performance</span>
              </h2>

              <p className="gray-c">
                Obtenez une vue complète de vos indicateurs clés avec des
                dashboards intuitifs et un monitoring en temps réel. Utilisez
                le report builder pour créer des rapports et présentations
                détaillés, adaptés à vos besoins stratégiques.
              </p>
            </div>

            <ModelCard
              key={modelsData[6]._id}
              plusPosition={modelsData[6].plusPosition}
              entitle={modelsData[6].entitle}
              text={modelsData[6].text}
              cardType={modelsData[6].cardType}
              images={modelsData[6].images}
              detailedText={modelsData[6].detailedText}
              _id={modelsData[6]._id}
            />
          </div>
          <div className="half-container service-column-mobile">
            <ModelCard
              key={modelsData[7]._id}
              plusPosition={modelsData[7].plusPosition}
              entitle={modelsData[7].entitle}
              text={modelsData[7].text}
              cardType={modelsData[7].cardType}
              images={modelsData[7].images}
              detailedText={modelsData[7].detailedText}
              _id={modelsData[7]._id}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
